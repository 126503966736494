import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyFormSubmit = lazy(
  () => import("./form-submit-BaEesRYg.js").then((module) => ({
    default: module.FormSubmit
  }))
);
function FormSubmit({
  uuid: _uuid,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyFormSubmit, { ...props });
}
const useFormSubmitContent = () => {
  return useMemo(
    () => ({
      submit: {
        component: FormSubmit
      }
    }),
    []
  );
};
export {
  LazyFormSubmit as FormSubmit,
  useFormSubmitContent
};
